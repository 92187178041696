import styled, { css } from 'styled-components';
import {
  createBreakpoint, a11yOnly,
} from '@styles';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: ${({ sectionTheme, theme }) => theme.palette[sectionTheme]};
  padding-top: 48px;
  padding-right: ${({ theme }) => theme.layout.gutter}px;
  padding-left: ${({ theme }) => theme.layout.gutter}px;
  padding-bottom: 48px;

  ${({ hiddenTitle }) => hiddenTitle && css`
    h1, h2 {
      ${a11yOnly};
    }
  `}
  position: relative;
  overflow-y: visible;
  z-index: ${({ zIndex }) => zIndex || 0};

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-wrap: nowrap;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: ${theme.layout.gutter}px;
    padding-left: ${theme.layout.gutter}px;
    ${({ minHeight }) => minHeight && (css` min-height: ${minHeight}px;`)}
  `)}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ hasCards }) => (hasCards ? '20px' : '0px')};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${({ hasCards }) => (hasCards ? '32px' : '0px')};
  `)}
`;

export const Text = styled.span`
  display: inline;
`;

export const LastWord = styled.span`
  display: inline-block;
  padding-right: 27px;
`;

export const ActionCardWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    flex-direction: row;
    flex-wrap: wrap;
  `)}
`;

export const MaxContent = styled.div`
  margin: 0px auto;
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;
`;

export const MaxGlobalContent = styled.div`
  margin: 0px auto;
  max-width: ${({ theme }) => theme.layout.wideMaxWidth}px;
  width: 100%;
`;

export const SingleActionCard = styled.li`
  width: 100%;
  margin-bottom: 50px;
  line-height: 1.3;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    width: calc(33.3333% - 20px);
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(3n+3) {
      margin-right: 0px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  `)}
`;
