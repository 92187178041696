import React from 'react';
import { graphql } from 'gatsby';
import {
  Layout, GeneralHero, ActionCard, SheetMap,
} from '@components';
import {
  ContentGutter, IntroWrapper, Header2,
} from '@styles';
import { getKeyValue, compileRichText, slugify } from '@utils';
import { LIGHT } from '@utils/vars';

import {
  ActionCardWrapper, SingleActionCard, Section, ContentWrapper, MaxContent, MaxGlobalContent,
} from './action-center.styles';

const ActionCenterTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data || {};

  const { locale } = pageContext || {};
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    title, intro, heroImage, actionCenterSections, documentDownloads, documentDownloadsText,
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      <GeneralHero
        title={title}
        heroImage={heroImage}
        documentDownloads={documentDownloads}
        documentDownloadsText={documentDownloadsText}
      />
      {intro && (
        <ContentGutter>
          <MaxContent>
            <IntroWrapper>
              {compileRichText({
                textTheme: 'primary', size: 'large', locale,
              })(intro)}
              <SheetMap />
            </IntroWrapper>
          </MaxContent>
        </ContentGutter>
      )}
      {actionCenterSections && actionCenterSections.map((section) => {
        const {
          sectionTitle, sectionIntro, actionCards, colorTheme = 'Dark Purple',
        } = section || {};
        const bgLookup = {
          'Dark Purple': 'primary',
          'Light Purple': 'lighterPrimary',
          Green: 'secondary',
          White: 'white',
        };
        const headerid = slugify(sectionTitle);
        const textTheme = (colorTheme === 'Light Purple' || colorTheme === 'White') ? 'primary' : 'white';
        const bgcolor = bgLookup[colorTheme] || 'primary';
        const hlColor = (colorTheme === 'Light Purple' || colorTheme === 'White') ? 'primaryHighlight' : 'secondaryHighlight';
        return (
          <Section sectionTheme={bgcolor} key={sectionTitle} id={headerid}>
            <MaxGlobalContent>
              <ContentWrapper hasCards={actionCards}>
                {sectionTitle && (
                <Header2 textTheme={textTheme}>
                  {sectionTitle}
                </Header2>
                )}
                {sectionIntro && (
                  compileRichText({
                    textTheme,
                    size: 'small',
                    locale,
                  })(sectionIntro)
                )}
              </ContentWrapper>
              {actionCards && (
              <ActionCardWrapper>
                {actionCards.map((action) => {
                  const {
                    internalCallToActionLink, callToActionText,
                    externalCallToActionLink, image, description, hoverImage,
                  } = action;
                  const linkProps = {
                    href: (
                      internalCallToActionLink && internalCallToActionLink.pageUrl
                    ) || externalCallToActionLink,
                    target: externalCallToActionLink ? '_blank' : '',
                  };
                  if (linkProps.href && callToActionText) {
                    return (
                      <SingleActionCard key={action.id}>
                        <ActionCard
                          {...linkProps}
                          size="xlarge"
                          text={action.callToActionText}
                          image={image}
                          description={description}
                          textTheme={textTheme}
                          bgTheme={bgcolor}
                          bdHighlight={hlColor}
                          hoverImage={hoverImage}
                        />
                      </SingleActionCard>
                    );
                  }
                  return null;
                })}
              </ActionCardWrapper>
              )}
            </MaxGlobalContent>
          </Section>
        );
      })}
    </Layout>
  );
};

export default ActionCenterTemplate;

export const actionCenterPageQuery = graphql`
  query ActionCenterBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulActionCenter {
          title
          intro {
            raw
            references {
              ...on ContentfulActionCenterSection {
                contentful_id
                id
                internal {
                  type
                }
                sectionTitle
              }
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
              ... on ContentfulMapEmbed {
                id
                contentful_id
                internal {
                  type
                }
                popupDescriptionFields
                popupTitleField
                title
                googleSheet
                description {
                  raw
                }
              }
            }
          }
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 974) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          documentDownloadsText
          documentDownloads {
            title
            file {
              url
            }
          }
          actionCenterSections {
            ...on ContentfulActionCenterSection {
              sectionTitle
              sectionIntro {
                raw
                references {
                  ...on ContentfulActionCenterSection {
                    contentful_id
                    id
                    internal {
                      type
                    }
                    sectionTitle
                  }
                  ... on ContentfulPage {
                    id
                    pageUrl
                    contentful_id
                    internal {
                      type
                    }
                  }
                  ... on ContentfulMapEmbed {
                    id
                    contentful_id
                    internal {
                      type
                    }
                    tabbableMapField
                    popupDescriptionFields
                    popupTitleField
                    title
                    googleSheet
                    description {
                      raw
                    }
                  }
                }
              }
              colorTheme
              actionCards {
                ... on ContentfulCard {
                  id
                  externalCallToActionLink
                  internalCallToActionLink {
                    pageUrl
                  }
                  callToActionText
                  image {
                    description
                    id
                    fluid(quality: 100, maxWidth: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  hoverImage {
                    description
                    id
                    fluid(quality: 100, maxWidth: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  description {
                    raw
                  }
                }
              }
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
